import { isStandaloneApp } from 'app/utils/browser';
import LINKS from 'app/const/links';


const {
    DELIVERY_PAGE,
    EVERYDAY_SET,
    SWEET_PAGE,
    VEG_PAGE,
    LITE_PAGE,
    NZ_PAGE,
    PREMIUM_SET,
    QUALITY_PAGE,
    SPA_MENU,
    SPA_SUBSCRIPTION_INFO,
    TRIAL_FIRST_ORDER_SET,
    FAST_PAGE,
    SOUP_PAGE,
    BREAKFAST_PAGE,

    KIDS_PAGE,
    GREEN_PAGE,
    GUESTS_PAGE,
    HITS_PAGE,
    CREATIVITY_PAGE,
    SPEED_PAGE,
    // CHRISTMAS_PAGE,
    PREMIUM_SET_GREEN_VERSION,
} = LINKS;

const isStandalone = isStandaloneApp();


const newTabLinkAttrs = {
    target: '_blank',
    rel: 'noopener noreferrer',
};

const currentTabLinkAttrs = {
    target: '_self',
};

export const getTopLevelLinks = (options) => {
    const {
        onClickNavLink,
    } = options;

    return [
        // {
        //     id: CHRISTMAS_PAGE.id,
        //     data: {
        //         titleTrKey: 'Старый новый год',
        //         href: CHRISTMAS_PAGE.hrefRu,
        //         type: 'link',
        //         badge: 'tree',
        //         linkAttrs: isStandalone ? currentTabLinkAttrs : newTabLinkAttrs,
        //         handler: onClickNavLink,
        //     },
        // },
        {
            id: TRIAL_FIRST_ORDER_SET.id,
            data: {
                titleTrKey: 'mobileNav.trial',
                href: TRIAL_FIRST_ORDER_SET.hrefBasket,
                type: 'link',
                badge: 'percent',
                linkAttrs: isStandalone ? currentTabLinkAttrs : newTabLinkAttrs,
                handler: onClickNavLink,
            },
        },
        {
            id: EVERYDAY_SET.id,
            data: {
                titleTrKey: 'mobileNav.tomorrow',
                href: EVERYDAY_SET.href,
                type: 'link',
                handler: onClickNavLink,
            },
        },
        {
            id: SPA_MENU.id,
            data: {
                titleTrKey: 'mobileNav.menuDishes',
                href: SPA_MENU.href,
                handler: onClickNavLink,
                type: 'navLink',
            },
        },
    ];
};


// Графа "Для особых случаев" в бургер меню
export const forSpecialOccasionsLinks = (options) => {
    const {
        locale,
        onClickNavLink,
    } = options;

    const isEnLocale = locale === 'en';

    return [
        {
            id: 'NY_PAGE',
            data: {
                title: 'На Рождество',
                href: '/blyuda-dlya-novogodnego-stola/',
                handler: onClickNavLink,
                type: 'link',
                trackId: 'ny',
                badge: 'tree',
            },
        },
        {
            id: KIDS_PAGE.id,
            data: {
                title: 'Детское',
                href: isEnLocale ? KIDS_PAGE.hrefEn : KIDS_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: KIDS_PAGE.trackId,
            },
        },
        {
            id: GREEN_PAGE.id,
            data: {
                title: 'Green',
                href: GREEN_PAGE.href,
                type: 'link',
                handler: onClickNavLink,
                // TODO:
                trackId: null,
            },
        },
        {
            id: GUESTS_PAGE.id,
            data: {
                title: 'К приходу гостей',
                href: GUESTS_PAGE.href,
                type: 'link',
                handler: onClickNavLink,
                // TODO:
                trackId: null,
            },
        },
        {
            id: HITS_PAGE.id,
            data: {
                title: 'Хиты',
                href: HITS_PAGE.href,
                type: 'link',
                handler: onClickNavLink,
                // TODO:
                trackId: null,
            },
        },
        {
            id: CREATIVITY_PAGE.id,
            data: {
                title: 'Время для творчества',
                href: CREATIVITY_PAGE.href,
                type: 'link',
                handler: onClickNavLink,
                // TODO:
                trackId: null,
            },
        },
        {
            id: 'SOUP_PAGE',
            data: {
                title: 'Супы',
                href: '/produkty-dlya-supa/',
                type: 'link',
                handler: onClickNavLink,
                trackId: SOUP_PAGE.trackId,
            },
        },
        {
            id: VEG_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.vegan',
                title: '   Вег-меню',
                href: isEnLocale ? VEG_PAGE.hrefEn : VEG_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: VEG_PAGE.trackId,
            },
        },
        {
            id: SPEED_PAGE.id,
            data: {
                title: 'Готовим за 15 минут',
                href: SPEED_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: FAST_PAGE.trackId,
            },
        },
        {
            id: SWEET_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.sweat',
                title: '   Печём вместе',
                href: isEnLocale ? SWEET_PAGE.hrefEn : SWEET_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: SWEET_PAGE.trackId,
            },
        },
        {
            id: 'BREAKFAST_PAGE',
            data: {
                titleTrKey: 'mobileNav.breakfast',
                title: 'Богемные завтраки',
                href: '/produkty-dlya-zavtraka/',
                type: 'link',
                handler: onClickNavLink,
                trackId: BREAKFAST_PAGE.trackId,
            },
        },
        {
            id: NZ_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.nz',
                title: 'Про запас',
                href: isEnLocale ? NZ_PAGE.hrefEn : NZ_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
                trackId: NZ_PAGE.trackId,
            },
        },


        // {
        //     id: 'PREMIUM_SET',
        //     data: {
        //         titleTrKey: 'mobileNav.premium',
        //         href: PREMIUM_SET.href,
        //         type: 'link',
        //         handler: onClickNavLink,
        //         trackId: PREMIUM_SET.trackId,
        //     },
        // },
    //     {
    //         id: FAST_PAGE.id,
    //         data: {
    //             titleTrKey: 'mobileNav.vegan',
    //             href: FAST_PAGE.hrefRu,
    //             type: 'link',
    //             handler: onClickNavLink,
    //             trackId: FAST_PAGE.trackId,
    //         },
    //     },
    ];
};

// Грвфф "О сервисе" в бургер меню
export const getAboutServiceLinks = (options) => {
    const {
        locale,
        onClickNavLink,
    } = options;

    const isEnLocale = locale === 'en';

    return [
        {
            id: QUALITY_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.quality',
                href: isEnLocale ? QUALITY_PAGE.hrefEn : QUALITY_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
            },
        },
        {
            id: DELIVERY_PAGE.id,
            data: {
                titleTrKey: 'mobileNav.delivery',
                href: DELIVERY_PAGE.hrefRu,
                type: 'link',
                handler: onClickNavLink,
            },
        },
        {
            id: 'Версия на другом языке',
            data: {
                titleTrKey: 'mobileNav.foreignLang',
                href: locale === 'ru' ? 'English version' : 'Русская версия',
                handler: onClickNavLink,
                type: 'link',
            },
        },
    ];
};


export const getFiveSetLinks = (options) => {
    const {
        locale,
        onClickNavLink,
    } = options;

    return [
        {
            id: 10,
            data: {
                titleTrKey: 'mobileNav.profitable',
                href: '/basket',
                type: 'link',
                handler: onClickNavLink,
            },
        },
        {
            id: 100,
            data: {
                titleTrKey: 'mobileNav.topFive',
                href: '/basket',
                type: 'link',
                handler: onClickNavLink,
            },
        },
        {
            id: 101,
            data: {
                titleTrKey: 'mobileNav.green',
                href: '/basket',
                handler: onClickNavLink,
                type: 'link',
            },
        },
    ];
};
