/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { abTestDataContext, CASHBACK_NEW_USERS } from 'app/containers/contexts/abTest.context';

// unfetched

export const useCashbackNewUsersABTest = () => {
    const {
        // @ts-ignore
        cashback_new_users, fetchABTestValue,
    } = useContext(abTestDataContext);

    const BAKSET_GOOD_DELIVERIES_USER_QUERY = gql`
    query BAKSET_GOOD_DELIVERIES_USER_QUERY {
            user {
                id
                hasGoodDeliveries
                ab_groups
            }
        }
    `;

    const { data: { user } } = useQuery(BAKSET_GOOD_DELIVERIES_USER_QUERY, {
        fetchPolicy: 'cache-only',
    });

    const isUserInABTestAlready = useMemo(
        () => user?.ab_groups?.some((group: string) => group.includes(CASHBACK_NEW_USERS)),
        [user?.ab_groups],
    );

    const isNeedToFetchABTest = useMemo(
        () => (
            ((user?.hasGoodDeliveries && isUserInABTestAlready) || user?.hasGoodDeliveries === false)
        && cashback_new_users === 'unfetched'),
        [
            user?.hasGoodDeliveries,
            isUserInABTestAlready,
            cashback_new_users,
        ],
    );

    useEffect(() => {
        if (isNeedToFetchABTest) {
            fetchABTestValue('cashback_new_users');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id]);
    return cashback_new_users;
};
