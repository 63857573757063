import React, { FC, useContext } from 'react';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';
import { selectionRecord } from 'app/components/banners/MenuSelectionsMobileSlider/selections.record';
import { analyticService } from 'global/services';

import css from './BasketGreenSelection.module.scss';

import BasketGreenSelectionSlider from './BasketGreenSelectionSlider';

const BasketGreenSelection: FC = () => {
    const { onOpenSelection } = useContext<any>(DishesSelectionContext);

    const targetTag = selectionRecord.find((selection) => selection.id === 'green');

    const onClickHandler = () => {
        analyticService.push({
            eventName: 'Upsell_Click',
            upsell_type: 'promo',
            banner_name: 'green',
        });
        onOpenSelection({ tag: targetTag?.tag || '1220' });
    };
    return (
        <div
            className={css.basketGreenSelectionRootWrapper}
            onClick={onClickHandler}
            role="none"
        >
            <div className={css.basketGreenSelectionRoot}>
                <div className={css.basketGreenSelectionSliderContainer}>
                    <div className={css.basketGreenSelectionTitle}>
                        Лёгкий подход
                        <br />
                        к лёгкой еде
                    </div>
                    <ul className={css.basketGreenSelectionDescription}>
                        <li>Много белка и овощей</li>
                        <li>Баланс БЖУ на 400-500 ккал</li>
                        <li>Новые интересные продукты!</li>
                    </ul>
                    <div className={css.basketHiddenBlock} />
                    <BasketGreenSelectionSlider />
                </div>
            </div>
        </div>
    );
};

export default BasketGreenSelection;
