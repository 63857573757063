import React, { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { analyticService } from 'global/services';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';
import { selectionRecord } from 'app/components/banners/MenuSelectionsMobileSlider/selections.record';
import { gql, useFragment } from '@apollo/client';
import { selectedPeriodVar } from 'app/apollo/reaction';
import css from './BasketUpsellSelections.module.scss';

import BasketUpsellSelectionsCard from '../BasketUpsellSelectionsCard';
import { ISelection } from '../../types';

const upsellSelections: ISelection[] = [
    {
        id: 'harry',
        title: 'Меню из Хогвартса',
        iconPath: 'harry',
        selectionTag: 'hp',
        type: 'selection',
    },
    {
        id: 'kids',
        title: 'Детское меню',
        iconPath: 'kids',
        selectionTag: 'kids',
        type: 'selection',
    },
    {
        id: 'sweets',
        title: 'Печем вместе',
        iconPath: 'sweet',
        selectionTag: 'sweets',
        type: 'selection',
    },
    // {
    //     id: 'green',
    //     title: 'Green',
    //     iconPath: 'green',
    //     selectionTag: 'green',
    //     type: 'selection',
    // },
    // {
    //     id: 'maxim',
    //     title: 'Вечер с любимыми',
    //     iconPath: 'maxim',
    //     selectionTag: 'maxim',
    //     type: 'selection',
    // },
    // {
    //     id: 'ny',
    //     title: 'Новый год',
    //     iconPath: 'ny',
    //     selectionTag: 'ny',
    //     type: 'link',
    //     href: '/blyuda-dlya-novogodnego-stola?period=2024-12-23',
    // },
    // {
    //     id: 'film',
    //     title: 'Дома, как в кино',
    //     iconPath: 'film',
    //     selectionTag: 'film',
    //     type: 'selection',
    // },
    // {
    //     id: 'dacha',
    //     title: 'На дачу',
    //     iconPath: 'dacha',
    //     selectionTag: 'dacha',
    //     type: 'link',
    //     href: '/dacha',
    // },
];

const BasketUpsellSelections: FC = () => {
    const { onOpenSelection } = useContext<any>(DishesSelectionContext);
    const history = useHistory();
    const selectedPeriod = selectedPeriodVar();


    const { data } = useFragment({
        fragment: gql`
            fragment CHECK_AHMAD_DISHES on cart {
                type
                period
                sections {
                    id
                    items {
                        dish_id
                    }
                }
            }
        `,
        from: {
            __ref: `cart:new:${selectedPeriod}`,
        },
    }) || { data: { sections: [] } };

    return (
        <div className={css.basketUpsellSelectionsRoot}>
            <div className={css.basketUpsellSelectionsWrapper}>
                {upsellSelections.map((selection: ISelection) => {
                    const targetSelection = selectionRecord.find((record) => record.id === selection.id);
                    const targetType = targetSelection?.type;


                    const onClickHandler = () => {
                        analyticService.push({
                            eventName: 'Upsell_Click',
                            upsell_type: 'something_special',
                            banner_name: selection.selectionTag,
                        });
                        if (targetType === 'link') {
                            // @ts-ignore
                            window.location = selection.href || '/';
                            return;
                        }
                        onOpenSelection({ tag: targetSelection?.tag || selection.selectionTag });
                    };
                    return (
                        <BasketUpsellSelectionsCard
                            key={selection.id}
                            title={selection.title}
                            iconPath={selection.iconPath}
                            onClickHandler={onClickHandler}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default BasketUpsellSelections;
