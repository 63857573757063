/* eslint-disable react/require-default-props */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useEffect, useContext, Suspense,
} from 'react';
import queryString from 'query-string';
import { Portal } from 'react-portal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Raven from 'raven-js';
import compose from 'lodash/flowRight';
import {
    useMutation, useQuery, useSuspenseQuery, gql,
} from '@apollo/client';

import { VIEW_CUSTOMIZATION_COMMENT } from 'app/graphql/network/customizationComment';
import { GET_CHECKOUT_DATA } from 'app/graphql/client/checkout';

import {
    cartType,
} from 'types/basketQuery.type';

import { analyticService } from 'global/services';
import connect from 'app/connect';

import { PERIOD_IS_DISABLED_ERROR_MESSAGE } from 'app/const/basket-errors';
import { SUBSCRIPTION_TYPES, isSubscription } from 'app/const/subscription';
import { CREATE_EVENT } from 'app/graphql/network/createEvent';
import { isDesktop } from 'app/utils/resolution';
import { LocaleContext } from 'app/containers/LocaleProvider';
import {
    CATEGORY, MenuDispatchContext,
} from 'app/containers/contexts/menu.context';
import {
    abTestDataContext, WELCOME_DISCOUNT_SIZE,
} from 'app/containers/contexts/abTest.context';
import { menuDatesState } from 'app/containers/contexts/menuDates.context';
import { LocationContext } from 'app/containers/LocationProvider';

import Responsive from 'app/components/Responsive';

import ErrorContent from 'app/components/ErrorContent';
import formatPrice from 'app/utils/price';


import { DialogAnimated } from 'app/components/Dialog';
import { UIButton, UIButtonBottomArea } from 'app/components/ui';
import FloatFooterDiscountCounter from 'app/components/FloatFooterDiscountCounter';
import { BasketSkeletonPage } from 'app/components/Skeletons/BasketPageSkeletons';
import client from 'app/apollo/client';
import { useCashbackNewUsersABTest } from 'app/hooks';
import { BASKET_ALL_DISHES_TAGS_FRAGMENT } from './graphql/basketAllDishesTagsFragment.graphql';

import {
    ReplacePopularCustomSetsEnum,
    TagsContentForABDialog,
    customizationTagsForABTest,
    customizationTagsThrowingOutFromABTest,
} from './enums/basket';

import LoadingBasket from './components/LoadingBasket';
import { BasketCheckArea } from './BasketCheckArea';

import BasketHeaderArea from './BasketHeaderArea';
import BasketTopArea from './BasketTopArea';
import BasketPromoArea from './BasketPromoArea';
import BasketListArea from './BasketListArea';
import { locales } from './basket.locales';
import { useBasketUISelector } from './basketStore/basketUiStore.context';

import './basket.scss';
import { pickCategoryReaction } from '../../apollo/reactions/filters';
import { setSubscriptionTypeReaction } from '../../apollo/reactions/subscription';
import { setPromocodeReaction } from '../../apollo/reactions/promocode';
import { getConfirmCustomizationLocales } from './components/BasketCustomizationTags/basket-customization-locales';
import { findMissingTag } from './utils/findMissingTag';
import { useBillingMutation } from 'app/hooks/useBillingMutation';

/* FETCH */
import { BASKET_PAGE_ROOT_QUERY } from './graphql/initialBasketPageQuery.graphql';
import { useCartMutationInBasket } from './hooks';
import EmptyBasket from './components/EmptyBasket';
import { BasketFooterButton } from './components/BasketFooterButton';
import { BasketFrame } from './CommonComponents/BasketFrame';
import { mapAllDishesTagsInBasket } from './utils/mapAllTagsInBasket';


// TODO: Выяснить что это за константа
const CLOSED = 'closed';

const backgroundColor = '#F8F8F8';

/* timeout for customization comment sidebar closing animation */
const OVERLAY_DISSAPEARING_DELAY = 700;

const possibleLoadingStatus = [
    'loading_basket',
    // 'loading_trial_basket',
    // 'loading_promo_selection_basket', // BR-948
    'loading_picture_size_test_basket', // BR-958
    'loading_picture_size_trial_test_basket', // BR-958
    'initial_basket',
];
const isDishesSectionsCountChanged = (cart, prevCart) => {
    const { sections } = cart;
    const { sections: prevSections } = prevCart;
    return sections.length !== prevSections.length;
};

const getDishIdsFromSection = (section) => section.items.map((i) => i.dish_id);

const getDishIds = (sections) => sections.reduce((acc, i) => [...getDishIdsFromSection(i), ...acc], []);

const isDishesCountChanged = (cart, prevCart) => {
    const { sections } = cart;
    const { sections: prevSections } = prevCart;

    return getDishIds(sections).length !== getDishIds(prevSections).length;
};

const isAllCategoriesInvisibled = (categories, invisibleCategories) => {
    const isBasketEmpty = categories.length === 0;
    const isLastCategoriesInvisibled = invisibleCategories.length !== 0 && categories.length === 1;

    return isBasketEmpty || isLastCategoriesInvisibled;
};

class Basket extends React.Component {
    state = {
        isCustomizationCommentAreaShown: false,
        isCustomizationCommentSidebarShown: false,
        isCustomizationDialogShown: false,
        customizationCommentShowingState: CLOSED, // opened, closed
        bodyInitialBackground: null,
        // isEnterTransitionEnded: false,
        invisibleCategories: [],
        invisibleDishes: [],
        dialogIngridient: null,
        isCustomizationEdited: false,
    };

    showPaperRecipeRow = true;

    // Life cycle ==============================================================

    componentDidMount() {
        const {
            location: { search },
            history,
        } = this.props;

        this.setBodyInitialBackground({ callback: this.setBodyBackground });
        // TODO: Вынести функцию в untils? Оформить в виде набора методов/функций для работы с query параметрами?
        const queryParams = new URLSearchParams(search);
        if (queryParams.has('st')) {
            queryParams.delete('st');
            history.replace({
                search: queryParams.toString(),
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            basketQuery: { cart, error },
        } = this.props;

        const {
            basketQuery: { cart: prevCart },
        } = prevProps;

        if (error) {
            return;
        }

        this.scrollToTopOnEntered(prevProps);

        const isCartsExist = cart && prevCart;

        if (isCartsExist && isDishesSectionsCountChanged(cart, prevCart)) {
            this.resetInvisibleCategories();
        }

        if (isCartsExist && isDishesCountChanged(cart, prevCart)) {
            this.updateInvisibleDishes();
        }
    }

    componentWillUnmount() {
        sessionStorage.removeItem('gotBasketFromPreview');
        this.resetBodyBackground();
    }

    checkDishesInBasketByTag(tagId) {
        const {
            basketQuery: {
                cart: {
                    sections,
                },
            },
        } = this.props;
        const allTagsByDish = mapAllDishesTagsInBasket(sections, true);

        const dishesWithoutTargetTag = Object.entries(allTagsByDish)
            .filter(([_, allDishTags]) => !allDishTags.some((tag) => tag.id === tagId))
            .map(([dishId]) => dishId);

        const dishesWithTargetTag = Object.entries(allTagsByDish)
            .filter(([_, allDishTags]) => allDishTags.some((tag) => tag.id === tagId))
            .map(([dishId]) => dishId);
        return { dishesWithTargetTag, dishesWithoutTargetTag };
    }

    // Handlers and Actions ====================================================

    setBodyInitialBackground({ callback }) {
        const { body } = document;
        this.setState({ bodyInitialBackground: body.style.backgroundColor }, callback);
    }

    setBodyBackground = () => {
        const { body } = document;
        body.style.backgroundColor = backgroundColor;
    };

    // eslint-disable-next-line react/sort-comp
    resetBodyBackground() {
        const { bodyInitialBackground } = this.state;
        const { body } = document;

        body.style.backgroundColor = bodyInitialBackground;
    }

    addInvisibleCategory(categoryId, callback) {
        const { invisibleCategories } = this.state;

        this.setState(
            { invisibleCategories: [categoryId, ...invisibleCategories] },
            callback,
        );
    }

    resetInvisibleCategories() {
        this.setState({ invisibleCategories: [] });
    }

    addInvisibleDish(dishId, callback) {
        const { invisibleDishes } = this.state;

        this.setState(
            { invisibleDishes: [dishId, ...invisibleDishes] },
            callback,
        );
    }

    updateInvisibleDishes() {
        const { basketQuery: { cart: { sections } } } = this.props;
        const { invisibleDishes } = this.state;

        const dishes = getDishIds(sections);
        const filteredInvisibleDishes = invisibleDishes.filter((i) => dishes.includes(i));

        this.setState({ invisibleDishes: filteredInvisibleDishes });
    }

    applyPromocodeHandler = async (nextPromocode) => {
        const {
            updateBilling,
            basketQuery,
        } = this.props;

        setPromocodeReaction({ promocode: nextPromocode });
        await updateBilling({
            variables: {
                promocode: nextPromocode,
            },
        });

        const { data: { cart } } = await basketQuery.refetch();

        const {
            promocode,
            isPromocodeCombineWithDiscount,
            isPromoCodeExists,
            isSubscriptionActivated,
        } = cart.discount_conditions;


        analyticService.push({
            isPromoCodeExists,
            isPromocodeCombineWithDiscount,
            isSubscriptionActivated,
            promo: promocode,
            eventName: 'Check_Send_Promo',
            sourceName: 'basket_check',
        });

        return {
            cart,
            promocodeConditions: cart.discount_conditions,
        };
    };

    /**
     * @note Метод скопипащен из Filters
     * TODO: Возможно перенести в BasketHeaderArea
     */
    toggleSubscription = async () => {
        const {
            // updateBilling,
            // basketQuery,
            subscriptionTypeQuery: {
                subscriptionType,
            },
            paymentMethod: { paymentMethod },
            mutateBilling,
        } = this.props;

        const nextType = isSubscription(subscriptionType)
            ? SUBSCRIPTION_TYPES.singlePay
            : SUBSCRIPTION_TYPES.subscription;

        analyticService
            .push({
                eventName: 'Check_Subscription_Toggle',
                eventValue: isSubscription(subscriptionType) ? 'Check_Unselect_Subscription' : 'Check_Select_Subscription',
            });
        setSubscriptionTypeReaction({ type: nextType });

        try {
            mutateBilling({ variables: { payment_method: isSubscription(nextType) ? 'recurrent' : paymentMethod } });
        } catch (e) {
            Raven.captureException(e);
        }
    };

    handleToCheckout = () => {
        const {
            openCheckout,
        } = this.props;

        openCheckout();
        analyticService.push({ eventName: 'Go_to_Checkout' });
    };

    handleOpenMobileDishDetails = (itemData) => {
        const {
            filterQuery: { menuFilter: { periods } },
            selectedFilters: { selectedPeriod },
            onClickMobileDishDetails,
            subscriptionType,
        } = this.props;

        onClickMobileDishDetails(itemData);

        const { item: { dish } } = itemData;
        const period = periods.find((p) => p.start === selectedPeriod);
        const category = period.categories.find((c) => c.id === String(dish.categoryId));

        analyticService.push({
            ...dish,
            category,
            source: 'basket',
            eventName: 'Dish_Details_Viewed',
            subscriptionType,
        }).push({
            id: dish.id,
            eventName: 'CityAds_Dish_Details_Viewed',
        });
    };

    // FIXIT: basketQuery.refetch is not a function
    handleChangePortion = async ({ value, id }) => {
        const { mutateItemInCart } = this.props;
        mutateItemInCart(
            { dishes: [{ dish_id: id, portions: value }], optimistic: 'portion' },
        );
    };

    handleRemoveDish = (dishId) => {
        const { mutateItemInCart } = this.props;
        mutateItemInCart(
            { dishes: [{ dish_id: dishId, portions: 0 }] },
        );
    };

    handleAddMoreButtonClick = async (categoryId) => {
        const {
            closeBasket,
            menuDispatchContext,
        } = this.props;

        pickCategoryReaction({ categoryId });
        menuDispatchContext({
            type: 'scrollTo',
            payload: { target: CATEGORY, value: categoryId },
        });
        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'category',
            action: 'on',
            categoryId,
        });

        closeBasket();
    };

    scrollToTopOnEntered(prevProps) {
        if (isDesktop()) return;
        const { transitionState } = this.props;
        const { transitionState: prevTransitionState } = prevProps;
        const isEntered = transitionState === 'entered' && prevTransitionState !== 'entered';
        if (isEntered) {
            window.scrollTo(0, 0);
        }
    }

    toggleCustomizationCommentSidebar() {
        const { isCustomizationCommentAreaShown: isCommentShown } = this.state;

        if (isCommentShown) this.setState({ isCustomizationCommentSidebarShown: isCommentShown });

        if (!isCommentShown) {
            setTimeout(() => this.setState({ isCustomizationCommentSidebarShown: isCommentShown }), OVERLAY_DISSAPEARING_DELAY);
        }
    }

    toggleCustomizationCommentArea = (isShown) => () => {
        const { isCustomizationCommentAreaShown } = this.state;

        this.setState(
            { isCustomizationCommentAreaShown: isShown || !isCustomizationCommentAreaShown },
            () => this.toggleCustomizationCommentSidebar(),
        );
    };

    setCustomizationCommentShowingState = (state) => () => this.setState({ customizationCommentShowingState: state });

    handleOpenCloseCustomizationDialog = (value) => {
        this.setState({ isCustomizationDialogShown: value });
    };


    // Render ==================================================================
    /**
     * @description Fill screen by color when there is transition to empty basket
     */
    renderFiller() {
        const { invisibleCategories } = this.state;
        const { basketQuery: { cart: { sections } }, prearedBasketAreas: { basketStyleModifier } } = this.props;
        const fillerClasses = classNames({
            'basket-filler': true,
            active: isAllCategoriesInvisibled(sections, invisibleCategories) && basketStyleModifier !== 'empty_basket',
        });

        return <div styleName={fillerClasses} />;
    }

    renderFooterButton(props = {}) {
        const {
            basketQuery: { cart: { sections, totals, discount_conditions: discountConditions } },
            closeBasket,
            localeContext: { locale },
        } = this.props;

        const { emptyButtonText, fullButtonText } = locales[locale];

        const { isOnlyGiftInBasket } = props;

        const isBasketEmpty = sections.length === 0 || isOnlyGiftInBasket;

        const originalPrice = isBasketEmpty ? null : totals.total_price + totals.delivery_price;
        const price = isBasketEmpty ? null : totals.total_common_price;
        const title = isBasketEmpty ? emptyButtonText : fullButtonText;

        const footerClasses = classNames({
            'basket-footer': true,
        });

        return (
            <div styleName={footerClasses}>
                <UIButtonBottomArea zIndex="10" position="static">
                    <FloatFooterDiscountCounter
                        promocodeConditions={discountConditions}
                    />
                    <UIButton
                        onClick={() => this.defineCurrentFooterButtonHandler(isBasketEmpty)}
                        aria-label="Оформить заказ"
                    >
                        <span className="title">{title}</span>
                        {price && (
                            <span className="price">
                                <span className="num">{formatPrice(price)}</span>
                                <span className="rub">₽</span>
                            </span>
                        )}
                        {originalPrice && originalPrice !== price && (
                            <span className="original-price">
                                <span className="num">{formatPrice(originalPrice)}</span>
                                {/* <span className="num">3 000</span> */}
                                <span className="rub">₽</span>
                            </span>
                        )}
                    </UIButton>
                </UIButtonBottomArea>
            </div>
        );
    }

    checkIsOtherTagExists = () => {
        const {
            userQuery,
        } = this.props;
        const customizationTags = userQuery?.user?.customizationTags || [];
        const hasOtherTagsInAccount = !customizationTags.every((tag) => customizationTagsThrowingOutFromABTest.includes(Number(tag)));
        return hasOtherTagsInAccount;
    };

    checkIsСontraditionSearchNeeded = () => {
        const {
            basketQuery: {
                cart: {
                    typeOfSet,
                },
            },
            userQuery,
        } = this.props;

        const customizationTags = userQuery?.user?.customizationTags || [];
        const hasOtherTagsInAccount = this.checkIsOtherTagExists();

        const isContradictionSearchNeeded = Boolean(
            ReplacePopularCustomSetsEnum[typeOfSet] !== undefined
            && customizationTags.length
            && !hasOtherTagsInAccount);

        return isContradictionSearchNeeded;
    };

    searchForCustomizationContradiction = () => {
        const {
            selectedFilters: { selectedPeriod },
            userQuery,
        } = this.props;

        const cart = client.readFragment({
            id: `cart:new:${selectedPeriod}`,
            fragment: BASKET_ALL_DISHES_TAGS_FRAGMENT,
        });

        const customizationTags = userQuery?.user?.customizationTags || [];

        const missingTags = findMissingTag(customizationTags, cart.sections);
        return missingTags;
    };

    findMostImportantContradictionTag = (missingTags) => {
        const missingTagsFromABTestEnum = [];
        missingTags.forEach((tag) => {
            if (customizationTagsForABTest.includes(Number(tag))) {
                missingTagsFromABTestEnum.push(tag);
            }
        });
        if (missingTagsFromABTestEnum.length) {
            // Пока берем просто первый
            return missingTagsFromABTestEnum[0];
        }
        return null;
    };

    handleSetIsCustomizationEdited = (value) => {
        this.setState({ isCustomizationEdited: value });
        if (value) {
            sessionStorage.setItem('is_customization_edited', 'true');
        }
    };

    defineCurrentFooterButtonHandler = (isBasketEmpty = false) => {
        if (isBasketEmpty) {
            const {
                closeBasket,
            } = this.props;
            closeBasket();
            return;
        }
        const isContradictionSearchNeeded = this.checkIsСontraditionSearchNeeded();
        if (!isContradictionSearchNeeded) {
            this.handleToCheckout();
            sessionStorage.removeItem('with_customization');
            return;
        }

        const { isCustomizationEdited } = this.state;

        const missingTags = this.searchForCustomizationContradiction();
        const hasCustomizationContradiction = Boolean(missingTags.length);

        const mostImportantContradictionTag = this.findMostImportantContradictionTag(missingTags);
        const tagFromEnumExists = mostImportantContradictionTag !== null;

        if (hasCustomizationContradiction && (mostImportantContradictionTag in TagsContentForABDialog)) {
            this.setState({ dialogIngridient: TagsContentForABDialog[mostImportantContradictionTag] });
        }

        const isShowContradictionDialogNeeded = Boolean(mostImportantContradictionTag)
            && tagFromEnumExists
            && !isCustomizationEdited;

        if (isCustomizationEdited) {
            sessionStorage.setItem('with_customization', 'false');
            sessionStorage.setItem('is_customization_edited', 'true');
            this.handleToCheckout();
            return;
        }

        if (isShowContradictionDialogNeeded) {
            this.handleOpenCloseCustomizationDialog(true);
            return;
        }
        sessionStorage.removeItem('with_customization');
        this.handleToCheckout();
    };

    render() {
        const {
            localeContext: { locale },
            prearedBasketAreas,
            prearedBasketAreas: {
                list: { isEmptyBasket },
            },

            // DATA
            checkoutData: { subdivision },
            basketQuery: {
                error,
                cart,
            },
            subscriptionTypeQuery: { subscriptionType },
            // STATE PROPS
            transitionState,
            hiddenElements,
            // ACTION
            closeBasket,
            onClickMobilePortions,
            openDeliveryPricePopup,
            onClickSubscriptionInfoOpen,
            // MUTATION
            isShifted,
            mutateItemInCart,
            isCartUpdating,
            isCartEmpty,
            abTestDataContext,
        } = this.props;

        const {
            invisibleCategories,
            invisibleDishes,
            isCustomizationCommentAreaShown,
            isCustomizationCommentSidebarShown,
            isCustomizationDialogShown,
            customizationCommentShowingState,
            dialogIngridient,
            isCustomizationEdited,
        } = this.state;

        const isBasketEmpty = cart?.sections.length === 0;

        // TODO: Подумать что сделать с ошибкой
        /* ERROR BASKET */
        if (error) {
            const isBasketErrorHandled = error.message && error.message.includes(PERIOD_IS_DISABLED_ERROR_MESSAGE);
            if (!isBasketErrorHandled) {
                return (
                    <div styleName="basket">
                        <LoadingBasket>
                            <ErrorContent />
                        </LoadingBasket>
                    </div>
                );
            }
        }

        const {
            sections,
        } = cart ?? { sections: [] };

        // FOOTER
        const giftSection = sections.find((s) => s.gift_category);
        const isOnlyGiftInBasket = giftSection && sections.length === 1;

        const isEditEnabled = !hiddenElements.edit_basket;

        const showAddMoreButton = !hiddenElements.backstep;

        const dialogTexts = dialogIngridient
            ? getConfirmCustomizationLocales(dialogIngridient[locale])[locale].dialog.success
            : null;

        /* CLASSES */
        const headerRootclasses = classNames({
            'basket-header': true,
            'basket-header--static': !(isEmptyBasket || customizationCommentShowingState === 'opened'),
        });

        return (
            <>
                {/* HEADER */}
                <div styleName={headerRootclasses}>
                    <BasketHeaderArea
                        hiddenElements={hiddenElements}
                        prearedBasketAreas={prearedBasketAreas}
                        customizationCommentShowingState={customizationCommentShowingState}
                        toggleCustomizationCommentArea={this.toggleCustomizationCommentArea}
                        closeBasket={closeBasket}
                    />
                </div>
                {/* TOP */}
                {isCartEmpty && <EmptyBasket locale={locale} />}
                {!isCartEmpty && (
                    <div styleName="basket-categories__scroll-content">
                        {/* DEPRECATED */}
                        {/* <BasketTopArea
                            {...this.props}
                            isCustomizationCommentAreaShown={isCustomizationCommentAreaShown}
                            isCustomizationCommentSidebarShown={isCustomizationCommentSidebarShown}
                            toggleCustomizationCommentArea={this.toggleCustomizationCommentArea}
                            setCustomizationCommentShowingState={this.setCustomizationCommentShowingState}
                            isEditEnabled={isEditEnabled}
                            handleSetIsCustomizationEdited={this.handleSetIsCustomizationEdited}
                        /> */}
                        {/* {this.renderSubHeaderArea({ isEditEnabled: isCommentEditEnabled })} */}
                        {/* LIST */}
                        <BasketListArea
                            {...this.props}
                            // ACTION
                            handleChangePortion={this.handleChangePortion}
                            handleRemoveDish={this.handleRemoveDish}
                            handleOpenMobileDishDetails={this.handleOpenMobileDishDetails}
                            handleAddMoreButtonClick={this.handleAddMoreButtonClick}
                            onClickMobilePortions={onClickMobilePortions}
                            mutateItemInCart={mutateItemInCart}
                            // SATE PROPS
                            invisibleCategories={invisibleCategories}
                            invisibleDishes={invisibleDishes}
                            showAddMoreButton={showAddMoreButton}
                            isEditEnabled={isEditEnabled}
                            subscriptionType={subscriptionType}
                            preparedBasketAreas={prearedBasketAreas}
                            isCartUpdating={isCartUpdating}
                            showPaperRecipeRow={this.showPaperRecipeRow}
                        />
                        {/* PROMO */}
                        <BasketPromoArea
                            preparedBasketAreas={prearedBasketAreas}
                            closeHandler={closeBasket}
                            handleSetIsCustomizationEdited={this.handleSetIsCustomizationEdited}
                        />
                        <BasketCheckArea
                            renderContext="basket"
                            prearedBasketAreas={prearedBasketAreas}
                            subdivision={subdivision}
                            // basket={basketQuery.cart}
                            locale={locale}
                            hiddenElements={hiddenElements}
                            onClickDeliveryPrice={openDeliveryPricePopup}
                            onToggleSubscription={this.toggleSubscription}
                            applyPromocodeHandler={this.applyPromocodeHandler}
                            subscriptionType={subscriptionType}
                            onClickSubscriptionInfoOpen={onClickSubscriptionInfoOpen}
                            basketDishUiStateDispatch={() => { }}
                            isCartUpdating={isCartUpdating}
                        />
                        {dialogTexts && !isCustomizationEdited && (
                            <Portal>
                                <DialogAnimated
                                    {...dialogTexts}
                                    isOpen={Boolean(isCustomizationDialogShown)}
                                    onConfirm={() => {
                                        sessionStorage.setItem('with_customization', 'false');
                                        this.handleToCheckout();
                                        this.handleOpenCloseCustomizationDialog(false);
                                    }}
                                    onReject={() => {
                                        sessionStorage.removeItem('with_customization');
                                        this.handleOpenCloseCustomizationDialog(false);
                                    }}
                                />
                            </Portal>
                        )}
                    </div>
                )}
                <Responsive mobile={this.renderFiller()} />
                {!isCustomizationCommentAreaShown && (
                    <div styleName="basket-footer">
                        <BasketFooterButton
                            handleToCheckout={() => this.defineCurrentFooterButtonHandler(isBasketEmpty)}
                            closeBasket={closeBasket}
                            isOnlyGiftInBasket={isOnlyGiftInBasket}
                            isCartUpdating={isCartUpdating}
                        />
                    </div>
                )}
            </>
        );
    }
}

Basket.propTypes = {
    /* Query and Mutation */
    basketQuery: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.shape({
            message: PropTypes.string,
        }),
        cart: PropTypes.shape(cartType),
        refetch: PropTypes.func.isRequired,
    }),

    /* ---- */
    basketDishUiStateDispatch: PropTypes.func,
    uiBasketState: PropTypes.shape({
        isWellcomeDiscountButton: PropTypes.bool,
        isAuthDetailOpen: PropTypes.bool,
    }),
    localeContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,

    locationContext: PropTypes.shape({
        locationKey: PropTypes.string.isRequired,
    }).isRequired,

    openCheckout: PropTypes.func.isRequired,
    closeBasket: PropTypes.func.isRequired,

    transitionState: PropTypes.string.isRequired,

    promocode: PropTypes.shape({}).isRequired,

    userQuery: PropTypes.shape({
        user: PropTypes.shape({
            phone: PropTypes.string,
        }),
    }).isRequired,

    filterQuery: PropTypes.shape({
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        }).isRequired,
    }).isRequired,
    menuDishesQuery: PropTypes.shape({
        allDishes: PropTypes.arrayOf(PropTypes.shape({})),
        basketDishes: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    selectedFilters: PropTypes.shape({
        selectedPeriod: PropTypes.string,
    }).isRequired,

    subscriptionTypeQuery: PropTypes.shape({
        subscriptionType: PropTypes.string.isRequired,
    }).isRequired,

    updateBilling: PropTypes.func.isRequired,

    paymentMethod: PropTypes.shape({
        paymentMethod: PropTypes.string,
    }).isRequired,

    onClickMobilePortions: PropTypes.func.isRequired,
    onClickMobileDishDetails: PropTypes.func,

    hiddenElements: PropTypes.shape({
        backstep: PropTypes.bool,
        payment_button_recurrent: PropTypes.bool,
        payment_button_online: PropTypes.bool,
        payment_button_offline: PropTypes.bool,
        edit_basket: PropTypes.bool,
    }).isRequired,

    checkoutData: PropTypes.shape({
        subdivision: PropTypes.string,
    }).isRequired,

    onClickSubscriptionInfoOpen: PropTypes.func.isRequired,

    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
    menuDispatchContext: PropTypes.func.isRequired,
    onDishRemoved: PropTypes.func.isRequired,

    openDeliveryPricePopup: PropTypes.func.isRequired,

    isShifted: PropTypes.bool,
    viewCustomizationComment: PropTypes.shape({
        comment: PropTypes.string,
    }),
    prearedBasketAreas: PropTypes.shape({
        basketStyleModifier: PropTypes.string.isRequired,
    }).isRequired,
};

const BasketAdapder = (props) => {
    /* CONTEXTS ------------------------------------------------------------- */
    const menuDispatchContext = useContext(MenuDispatchContext);
    const localeContext = useContext(LocaleContext);
    const locationContext = useContext(LocationContext);
    const menuDatesContext = useContext(menuDatesState);
    // TODO: удалить после завершения AB-теста BR-1049 и AB-теста BR-1113 и BR-1122
    const abTestData = useContext(abTestDataContext);
    const {
        fetchABTestValue,
        replace_popular_custom: replacePopularCustom,
        setABTestValue,
    } = abTestData;

    /* HOOKS ---------------------------------------------------------------- */
    const { prearedBasketAreas, ...restProps } = useBasketUISelector({
        ...props,
        menuDispatchContext,
        localeContext,
        locationContext,
        abTestDataContext: abTestData,
        menuDatesContext,
    });

    /* DA ------------------------------------------------------------------- */
    const { mutateBilling } = useBillingMutation();

    const { data } = useSuspenseQuery(BASKET_PAGE_ROOT_QUERY, {
        fetchPolicy: 'cache-and-network',
    });
    const { data: { checkoutData } } = useQuery(GET_CHECKOUT_DATA, {
        context: {
            message: 'basket:root:BasketAdapder @client',
        },
    });
    useCashbackNewUsersABTest();

    const [createEventMutation] = useMutation(CREATE_EVENT);
    const { isCartUpdating, mutateItemInCart } = useCartMutationInBasket();

    const {
        data: { viewCustomizationComment },
    } = useQuery(VIEW_CUSTOMIZATION_COMMENT);

    const {
        selectedFilters: { selectedPeriod },
        basketQuery: { cart, error: basketQueryError },
    } = props;

    /* EFFECTS -------------------------------------------------------------- */
    useEffect(() => {
        if (cart?.sections.length) {
            analyticService.push({
                eventName: 'Track_Navigate',
                step: 1,
                sections: cart.sections,
                totals: cart.totals,
                period: selectedPeriod,
                typeOfSet: cart.typeOfSet,
            }).push({
                eventName: 'CityAds_Basket_Navigate',
                sections: cart.sections,
            });

            if (window.location.search.search('adid=') > 0) {
                createEventMutation({
                    variables: {
                        period: selectedPeriod,
                        eventType: 'cart_open',
                    },
                });
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * @note это проверка нужна для отображения пустой корзины и корзины загрузки
     * @TODO: вынести пустую корзину и корзину загрузки на уровень выше
     */
    useEffect(() => {
        if (!cart) return;

        const {
            typeOfSet,
            discount_conditions: { promocode },
        } = cart;

        if (typeOfSet === '5x2' && !promocode) {
            fetchABTestValue(WELCOME_DISCOUNT_SIZE);
        }
    }, [cart, fetchABTestValue, setABTestValue]);

    const { basketStyleModifier } = prearedBasketAreas;

    /* RENDER --------------------------------------------------------------- */
    if (possibleLoadingStatus.includes(basketStyleModifier)) {
        return <BasketSkeletonPage />;
    }

    if (basketQueryError) {
        return (
            (
                <Basket
                    {...props}
                    {...restProps}
                    checkoutData={checkoutData}
                    menuDispatchContext={menuDispatchContext}
                    viewCustomizationComment={viewCustomizationComment}
                    replacePopularCustom={replacePopularCustom}
                    mutateItemInCart={mutateItemInCart}
                    isCartUpdating={isCartUpdating}
                    isCartEmpty={data?.cart?.isSectionsEmpty}
                    prearedBasketAreas={prearedBasketAreas}
                    mutateBilling={mutateBilling}
                />
            )
        );
    }

    /**
      * @note управляющая логика анимацией добавления в корзину блюд
      * @TODO: подумать как объединить добавление и удалению блюд в одном компоненте
      */
    return (
        <Basket
            {...props}
            {...restProps}
            checkoutData={checkoutData}
            mutateItemInCart={mutateItemInCart}
            isCartUpdating={isCartUpdating}
            isCartEmpty={data?.cart?.isSectionsEmpty}
            menuDispatchContext={menuDispatchContext}
            viewCustomizationComment={viewCustomizationComment}
            replacePopularCustom={replacePopularCustom}
            prearedBasketAreas={prearedBasketAreas}
            mutateBilling={mutateBilling}
        />
    );
};

function BasketRootContainer(props) {
    return (
        <BasketFrame>
            <Suspense fallback={<BasketSkeletonPage />}>
                <BasketAdapder {...props} />
            </Suspense>
        </BasketFrame>
    );
}

BasketAdapder.propTypes = {
    basketQuery: PropTypes.shape({
        cart: PropTypes.shape(cartType),
        error: PropTypes.shape({}),
    }),
    selectedFilters: PropTypes.shape({
        selectedPeriod: PropTypes.string.isRequired,
    }).isRequired,
};

export default compose(
    connect,
    React.memo,
)(BasketRootContainer);

// FOR NY

// filterDishesForNYPeriods() {
//     const {
//         basketQuery: {
//             cart: {
//                 sections,
//                 typeOfSet,
//             },
//         },
//         mutateItemInCart,
//     } = this.props;

//     if (typeOfSet === 'everyday') {
//         return;
//     }

//     const period = selectedPeriodVar();

//     const {
//         dishesWithoutTargetTag: dishesWithoutNYTag,
//         dishesWithTargetTag: dishesWithNYTag,
//     } = this.checkDishesInBasketByTag(NY_DISHES);
//     const {
//         dishesWithTargetTag: dishesWithNYDessertsTag,
//     } = this.checkDishesInBasketByTag(NY_DESSERT);

//     const isNYPeriod = period === NYPeriodStart;
//     const isNearNYPeriod = period === firstNYPeriodCustomStart || period === extraPeriodNYStart;

//     let dishesCollectionToDelete = [];
//     if (isNYPeriod) {
//         dishesCollectionToDelete = dishesWithoutNYTag;
//     }
//     if (isNearNYPeriod) {
//         dishesCollectionToDelete = dishesWithNYTag;
//     }

//     if (dishesCollectionToDelete.length) {
//         const allDishesToDelete = sections
//             .map((s) => s.items)
//             .flat()
//             .filter((dish) => {
//                 const hasDessertTag = dishesWithNYDessertsTag.includes(dish.dish_id);
//                 if (hasDessertTag) {
//                     return null;
//                 }
//                 return dishesCollectionToDelete.includes(dish.dish_id);
//             });
//         mutateItemInCart(
//             { dishes: allDishesToDelete.map((dish) => ({ dish_id: dish.dish_id, portions: 0 })), optimistic: 'portion' },
//         );
//     }
// }
